<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="period">Periode</label>
                    <Calendar selectionMode="range"  v-tooltip.top="'Maksimal 30 hari'" :manualInput="false" v-model="period" :maxDate="maxDate" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="user">User</label>
                    <Dropdown v-model="filters.userid" :loading="loadingDropdownUser" :options="dataDropdownUser" optionLabel="nama" optionValue="userid" placeholder="Pilih User" :filter="true" :showClear="true" @filter="searchDropdownUser($event, 'filter')"/>
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="tipe">Tipe</label>
                    <Dropdown v-model="filters.tipe" :loading="loadingDropdownTipe" :options="dataDropdownTipe" optionLabel="name" optionValue="code" placeholder="Pilih Tipe" :showClear="true"/>
                </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>

import moment from 'moment';

export default {
    data() {
        return {
            // loading
            loading: false,

            // dataDropdown
            loadingDropdownUser: false,
            loadingDropdownTipe: false,

            // dataDropdown
            dataDropdownUser: null,
            dataDropdownTipe: [
                {name: 'New', code: 'new'},
                {name: 'Existing', code: 'existing'},
            ],

            // filter
            filters: {
                userid : null,
                tipe : null,
            },
            period: [new Date(), new Date()],
            maxDate: null,
        }
    },
    mounted() {
        this.searchDropdownUser('');
    },
    created(){
    },
    watch: {
        period(){
            //max range 30 days
            this.maxDate =  new Date(this.period[0].getFullYear(), this.period[0].getMonth(), this.period[0].getDate() + 30);
        }
    },
    computed:{
        period_start_label(){ 
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        period_end_label(){ 
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
    methods: {
        // DROPDOWN
        searchDropdownUser(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.duser.filterValue = valueEdit;
                }

                if(purpose == "filter"){
                    this.loadingDropdownUser = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/filter-userapp',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "filter"){
                        this.dataDropdownUser = res.data.data;
                        this.loadingDropdownUser = false;
                    }else if(purpose == null){
                        this.dataDropdownUser = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        getDataTable(){
            if(
              this.period_start_label !== null && 
              this.period_end_label !== null && 
              this.period_start_label !== 'Invalid date' && 
              this.period_end_label !== 'Invalid date'
            ){  
                this.$emit('submit');
            }else{
                 alert('Pastikan rentang waktu sudah benar atau tidak kosong');
            }
        }
    }
}
</script>

